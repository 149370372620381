import React from "react";

import "./Partners.scss";
import { partnersConfig } from "../../../config/config.ts";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile.ts";

const Partners = () => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <div className="partners-container">
      <h2>{t("ourPartners")}</h2>
      <div className="partner-category">
        {partnersConfig.petabyte.map((image) => (
          <a
            href={image.url}
            target="_blank"
            key={image.name}
            rel="noreferrer noopener"
          >
            <div className="partner-container">
              <img
                src={image.path}
                alt={image.name}
                height={220 / (isMobile ? 2 : 1)}
              />
            </div>
          </a>
        ))}
      </div>
      <div className="partner-category">
        {partnersConfig.terabyte.map((image) => (
          <a
            href={image.url}
            target="_blank"
            key={image.name}
            rel="noreferrer noopener"
          >
            <div className="partner-container">
              <img
                src={image.path}
                alt={image.name}
                height={130 / (isMobile ? 2 : 1)}
              />
            </div>
          </a>
        ))}
      </div>
      <div className="partner-category">
        {partnersConfig.gigabyte.map((image) => (
          <a
            href={image.url}
            target="_blank"
            key={image.name}
            rel="noreferrer noopener"
          >
            <div className="partner-container">
              <img
                key={image.name}
                src={image.path}
                alt={image.name}
                height={70 / (isMobile ? 2 : 1)}
              />
            </div>
          </a>
        ))}
      </div>
      <div className="partner-category">
        {partnersConfig.megabyte.map((image) => (
          <a
            href={image.url}
            target="_blank"
            key={image.name}
            rel="noreferrer noopener"
          >
            <div className="partner-container">
              <img
                src={image.path}
                alt={image.name}
                height={40 / (isMobile ? 2 : 1)}
              />
            </div>
          </a>
        ))}
      </div>
      <div className="buttons-container">
        {/* <div className="main-button">{t("becomeAPartner")}</div>
        <div className="alt-button">{t("plan")}</div> */}
        <button
          onClick={() =>
            window.open(
              i18n.language === "en"
                ? "/pdfs/plan_en.pdf"
                : "/pdfs/plan_fr.pdf",
              "_blank"
            )
          }
          className="main-button"
        >
          {t("plan")}
        </button>
      </div>
    </div>
  );
};

export default Partners;
